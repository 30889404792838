.navbar {
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 80px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: right;
  margin: 0;
  padding: 0;
  gap: 30px;
}

.nav-menu li a {
  color: #f0f0f0;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1em;
  transition: color 0.3s ease;
}

.nav-menu li a:hover {
  color: #c18c8c;
}

.nav-buttons {
  display: flex;
  align-items: center;
  padding-left: 45px;

  gap: 20px;
}

.cta-button {
  padding: 10px 25px;
  background-color: #ffffff;
  color: #58010b;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #58010b;
  color: #f0f0f0;
}

.mobile-menu-button {
  display: none;
}


.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2.2rem;
  height: 2.2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.4rem;
  z-index: 1100;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.hamburger-menu:hover {
  background-color: rgba(255, 38, 0, 0.1);
}

.hamburger-line {
  width: 100%;
  height: 2px;
  background: #ffffff;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}


.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  padding-right: 60px;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  padding-top: 80px;
}

.mobile-menu.active {
  opacity: 1;
  visibility: visible;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.mobile-nav-items {
  list-style: none;
  padding: 20px 0;
  margin: 0;
  text-align: center;
}

.mobile-nav-items ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-nav-items li {
  margin: 25px 0;
  opacity: 0;
  transform: translateY(20px);
}

.mobile-menu.active .mobile-nav-items li {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease;
}

.mobile-nav-items li:nth-child(1) { transition-delay: 0.1s; }
.mobile-nav-items li:nth-child(2) { transition-delay: 0.2s; }
.mobile-nav-items li:nth-child(3) { transition-delay: 0.3s; }
.mobile-nav-items li:nth-child(4) { transition-delay: 0.4s; }

.mobile-nav-items a {
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 12px 24px;
  border-radius: 8px;
  transition: all 0.3s ease;
  display: inline-block;
}

.mobile-nav-items a:hover {
  background-color: rgba(0, 123, 255, 0.1);
  transform: translateX(5px);
}

.mobile-nav-items .dropdown-button {
  font-size: 1.3em;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 12px 24px;
  color: #333333;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.mobile-nav-items .dropdown-button::after {
  content: '▾';
  display: inline-block;
  margin-left: 8px;
  transition: transform 0.3s ease;
}

.mobile-nav-items .dropdown.active .dropdown-button::after {
  transform: rotate(180deg);
}

.mobile-nav-items .dropdown-menu {
  position: static;
  background: none;
  box-shadow: none;
  opacity: 1;
  visibility: visible;
  transform: none !important;
  padding: 0;
  margin: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.mobile-nav-items .dropdown-menu.active {
  max-height: 400px;
}

.mobile-nav-items .dropdown-menu li {
  margin: 10px 0;
  opacity: 1;
  transform: none;
}

.mobile-nav-items .dropdown-menu li a {
  padding: 8px 0;
  color: #333333 !important;
  font-size: 1.1em;
}

.mobile-nav-items .dropdown-menu li a:hover {
  background: none;
  color: #58010b !important;
}

.hamburger-menu.active .hamburger-line:first-child {
  transform: rotate(45deg);
  background: #270005;
}

.hamburger-menu.active .hamburger-line:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}

.hamburger-menu.active .hamburger-line:nth-child(3) {
  transform: rotate(-45deg);
  background: #270005;
}

.dropdown {
  position: relative;
}

.dropdown-button {
  background: none;
  border: none;
  color: #f0f0f0;
  font-weight: 500;
  font-size: 1.1em;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown-button::after {
  content: '▾';
  display: inline-block;
  margin-left: 4px;
  transition: transform 0.3s ease;
}

.dropdown-button:hover {
  color: #c18c8c;
}

.dropdown:hover .dropdown-button::after {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-10px);
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 8px 0;
  margin: 8px 0 0;
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
}

.dropdown-menu li {
  padding: 0;
  margin: 0;
}

.dropdown-menu li a {
  color: #333333 !important;
  padding: 8px 16px;
  display: block;
  text-decoration: none;
  transition: background-color 0.3s ease;
  text-align: center;
}

.dropdown-menu li a:hover {
  background-color: #f5f5f5;
  color: #58010b !important;
}

@media (max-width: 768px) {
  .navbar {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
  }

  .navbar-container {
    height: 100%;
    padding: 0 20px;
  }

  .hamburger-menu {
    display: flex;
  }

  .nav-menu, .nav-buttons {
    display: none;
  }

  .mobile-menu {
    display: block;
    padding-top: 0px;
  }

  .mobile-menu.active {
    width: 100%;
  }

 
  .mobile-nav-items .cta-button {
    margin-top: 20px;
    padding: 12px 30px;
    font-size: 1.1em;
    width: auto;
    display: inline-block;
    background-color: #58010b;
    color: #ffffff;
  }

  .mobile-nav-items .dropdown-button {
    font-size: 1.3em;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding: 12px 24px;
    color: #333333;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .mobile-nav-items .dropdown-menu {
    position: static;
    background: none;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    transform: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .mobile-nav-items .dropdown-menu.active {
    max-height: 400px;
  }

  .mobile-nav-items .dropdown-menu li a {
    padding: 8px 0;
    color: #333333 !important;
  }

  .mobile-nav-items .dropdown-menu li a:hover {
    background: none;
    color: #58010b !important;
  }

  .dropdown:hover .dropdown-menu {
    transform: none;
  }
}

@media (max-width: 480px) {
  .navbar-container {
    padding: 0 15px;
  }

  .hamburger-menu {
    width: 2rem;
    height: 2rem;
  }

  .mobile-nav-items a {
    font-size: 1.2em;
    padding: 10px 20px;
  }
} 