.admin-section {
  padding: 40px 0;
  background-color: #f8f9fa;
}

.admin-section .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.admin-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.admin-tools {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} 